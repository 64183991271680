import React from "react";
import "./Terms.css";

export default function Terms() {
  return (
    <section className="terms-section">
      <div className="terms-container">
        <h1 className="terms-title">Terms and Conditions</h1>
        <p className="terms-description">
          Welcome to <strong>FilmTV</strong>. By accessing or using our services, you agree to comply with and be bound by these Terms and Conditions.
        </p>

        <h2>1. Acceptance of Terms</h2>
        <p>
          By using the services provided by <strong>FilmTV</strong>, you agree to abide by these terms. If you do not agree to these terms, you must not use our services.
        </p>

        <h2>2. User Accounts</h2>
        <p>
          To access certain features of <strong>FilmTV</strong>, you must create a user account. You are responsible for maintaining the confidentiality of your account details and for all activities that occur under your account.
        </p>

        <h2>3. Content Usage</h2>
        <p>
          All content on <strong>FilmTV</strong> is provided for personal, non-commercial use only. You agree not to download, copy, distribute, or otherwise exploit any content for commercial purposes without prior permission.
        </p>

        <h2>4. Privacy and Data Collection</h2>
        <p>
          Your use of <strong>FilmTV</strong> is also governed by our Privacy Policy. Please refer to our <a href="/privacy-policy">Privacy Policy</a> for more details on how we collect and use your data.
        </p>

        <h2>5. Prohibited Activities</h2>
        <p>
          You agree not to engage in any of the following activities:
        </p>
        <ul>
          <li>Accessing or attempting to access other users' accounts without authorization.</li>
          <li>Uploading or sharing any content that violates intellectual property rights or is illegal.</li>
          <li>Using the platform to distribute malware or other harmful software.</li>
        </ul>

        <h2>6. Termination of Account</h2>
        <p>
          We reserve the right to suspend or terminate your account at any time, without prior notice, if we believe you have violated these Terms and Conditions.
        </p>

        <h2>7. Limitation of Liability</h2>
        <p>
          <strong>FilmTV</strong> will not be liable for any damages arising from the use or inability to use our services, including indirect, incidental, or consequential damages.
        </p>

        <h2>8. Changes to Terms</h2>
        <p>
          We reserve the right to update or modify these Terms and Conditions at any time. Any changes will be posted on this page with an updated "Last Updated" date.
        </p>

        <h2>9. Contact Us</h2>
        <p>
          If you have any questions regarding these Terms and Conditions, please contact us at <a href="mailto:info@filmtv.site">info@filmtv.site</a>.
        </p>
      </div>
    </section>
  );
}
