import React from 'react';

import './footer.scss';

import { Link } from 'react-router-dom';

import bg from '../../assets/footer-bg.jpg';
import logo from '../../assets/logo2.png';

const Footer = () => {
    return (
        <div className="footer" style={{backgroundImage: `url(${bg})`}}>
            <div className="footer__content container">
                <div className="footer__content__logo">
                    <div className="logo">
                    <img src={logo} alt="" />
                        <h1>
                        <Link to="/">Film TV</Link>
                        </h1>
                    
                       
                       
                    </div>
                    
                </div>
               
                <div className="footer__content__menus">
                    <div className="footer__content__menu">
                        <Link to="/">Home</Link>
                        {/* <Link to="/">Contact us</Link> */}
                        <Link to="/terms">Term of services</Link>
                        <Link to="/about">About us</Link>
                        <Link to="/privacy">Pravacy policy</Link>
                    </div>
                    <div className="footer__content__menu">
                        {/* <Link to="/">Live</Link>
                        <Link to="/">FAQ</Link>
                        <Link to="/">Premium</Link> */}
                   
                    </div>
                    {/* <div className="footer__content__menu">
                        <Link to="/">You must watch</Link>
                        <Link to="/">Recent release</Link>
                        <Link to="/">Top IMDB</Link>
                    </div> */}
                </div>
            </div>
        </div>
    );
}

export default Footer;
