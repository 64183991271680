import React from "react";
import "./PrivacyPolicy.css";

export default function PrivacyPolicy() {
  return (
    <section className="privacy-policy-section">
      <div className="privacy-policy-container">
        <h1 className="privacy-policy-title">Privacy Policy</h1>
        <p className="privacy-policy-description">
          At <strong>FilmTV</strong>, your privacy is of utmost importance to us. This Privacy Policy explains how we collect, use, and safeguard your personal information when you use our website and services.
        </p>

        <h2>Information We Collect</h2>
        <ul>
          <li>Personal details you provide during registration, such as your name, email address, and preferences.</li>
          <li>Non-personal information such as your IP address, browser type, and operating system.</li>
          <li>Activity data including the pages you visit and the time you spend on our website.</li>
        </ul>

        <h2>How We Use Your Information</h2>
        <p>
          We use your information to improve our services, provide personalized recommendations, and ensure the security of our platform.
        </p>
        <ul>
          <li>To enhance your viewing experience by tailoring content to your interests.</li>
          <li>To communicate updates, special offers, and promotions.</li>
          <li>To analyze website performance and resolve technical issues.</li>
        </ul>

        <h2>Data Security</h2>
        <p>
          We take reasonable measures to protect your data against unauthorized access, alteration, or destruction. However, no method of transmission over the internet is completely secure.
        </p>

        <h2>Third-Party Services</h2>
        <p>
          We may use third-party services such as analytics tools and advertising partners, which may collect and process your information under their privacy policies.
        </p>

        <h2>Changes to This Policy</h2>
        <p>
          We reserve the right to update this Privacy Policy at any time. Changes will be posted on this page with an updated "10/08/24" date.
        </p>

        <h2>Contact Us</h2>
        <p>
          If you have any questions or concerns about this Privacy Policy, please contact us at <a href="mailto:info@filmtv.site">info@filmtv.site</a>.
        </p>
      </div>
    </section>
  );
}
