import React, { useState, useEffect } from 'react';
import { BrowserRouter } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import './App.scss';
import 'swiper/swiper.min.css';
import './assets/boxicons-2.0.7/css/boxicons.min.css';
import AppRoutes from './config/AppRoutes';
import Header from './components/header/Header';
import { auth, provider ,db} from './firebase';
import { signInWithCredential, GoogleAuthProvider } from 'firebase/auth';
import logo from '../src/assets/logo2.png';
import { ref, set, get, update, child  } from "firebase/database";

function App() {
  const [currentUser, setCurrentUser] = useState(null);
  const [loading, setLoading] = useState(true);


  const userEmail = localStorage.getItem("email");
  
  useEffect(() => {
    const fetchUserData = () => {
      auth.onAuthStateChanged((user) => {
        if (user) {
          setCurrentUser(user);
          localStorage.setItem("uid", user.uid);
        } else {
          setCurrentUser(null);
        }
        setLoading(false);
      });
    };

    fetchUserData();
  }, []);

  useEffect(() => {
    if (!userEmail) {
      const script = document.createElement('script');
      script.src = 'https://accounts.google.com/gsi/client';
      script.async = true;
      script.onload = () => {
        window.google.accounts.id.initialize({
          client_id: '792218548234-r18lg2ep2qrnnhnq0smci6f8i3uompor.apps.googleusercontent.com',
          callback: async (response) => {
            try {
              const credential = GoogleAuthProvider.credential(response.credential);
              const result = await signInWithCredential(auth, credential);
              const user = result.user;
              
              setCurrentUser(user);
              const token = await user.getIdToken();
              localStorage.setItem("email", user.email);
              localStorage.setItem("token", token);
              localStorage.setItem("picture", user.photoURL);
              localStorage.setItem("userid", user.uid);
              const userid = user.uid;
              const usersRef = ref(db, `users/${userid}`);
              await set(usersRef, {
                userEmail: user.email,
                userid: userid,
                createdAt: new Date().toISOString(),
                photoURL: user.photoURL,
                displayName: user.displayName,
              });
  
              setLoading(true); // Trigger re-render
              setLoading(false);
            } catch (error) {
              console.error('Google One Tap sign-in error:', error);
            }
          },
          auto_select: false,
        });
  
        window.google.accounts.id.prompt();
      };
      document.body.appendChild(script);
  
      return () => {
        // Clean up Google One Tap
        const script = document.querySelector('script[src="https://accounts.google.com/gsi/client"]');
        if (script) {
          document.body.removeChild(script);
        }
      };
    }
  }, [userEmail]);  // Depend on userEmail instead of currentUser
  
  return (
    <BrowserRouter>
      <Helmet>
        <meta name="title" content="Film TV - HD Movies & Tv series" />
        <meta name="description" content="Watch HD Movies and TV series online for free. Stream the latest releases in high quality without any cost." />
        <meta property="og:type" content="website" />
        <meta name="og:title" content="Film TV - HD Movies & Tv series" />
        <link rel="canonical" href="https://filmtv.site" />
        <meta property="og:url" content="https://www.filmtv.site/" />
        <meta property="og:image" content={`https://filmtv.site${logo}`} />
        <meta property="twitter:card" content="summary" />
        <meta property="twitter:url" content="https://www.filmtv.site/" />
        <meta name="keywords" content="free movies online, free movie websites, watch free movies, movies, film 2024, streaming film, tv series, cinema" />
        <meta property="twitter:title" content="Film TV - HD Movies & Tv series" />
        <meta property="twitter:description" content="Watch HD Movies and TV series online for free. Stream the latest releases in high quality without any cost." />
        <meta property="twitter:image" content={`https://filmtv.site${logo}`} />
      </Helmet>
      {loading ? (
        <div className="spinner-container">
          <div className="pulsing-logo"></div>
        </div>
      ) : (
        <>
          <Header />
          <AppRoutes />
        </>
      )}
    </BrowserRouter>
  );
}

export default App;
