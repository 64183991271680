import React from "react";
import "./About.css";

export default function About() {
  return (
    <section className="about-section">
      <div className="about-container">
        <h1 className="about-title">About FilmTV</h1>
        <p className="about-description">
          At <strong>FilmTV</strong>, we bring the magic of cinema and television right to your fingertips. Explore our curated collection of HD-quality movies and TV series, designed to cater to every taste. Whether you're into action, comedy, romance, or thrillers, we have it all.
        </p>
        <div className="about-highlight">
          <h2>What We Offer</h2>
          <ul>
            <li>HD streaming in 1080p, 720p, and 360p.</li>
            <li>Multi-language subtitle support for a global audience.</li>
            <li>Seamless browsing by genre, rating, and popularity.</li>
          </ul>
        </div>
        <p className="about-cta">
          Join us on a journey of storytelling and entertainment. Start streaming today with <strong>FilmTV</strong> and enjoy the ultimate viewing experience!
        </p>
      </div>
    </section>
  );
}
