// DetailPage.js
import React, { useEffect, useRef, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import tmdbApi from "../../api/tmdbApi";
import apiConfig from "../../api/apiConfig";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye } from "@fortawesome/free-solid-svg-icons";
import VideoList from "../../pages/detail/VideoList";
import MovieList from "../movie-list/MovieList";
import "../player/player.css";
import { Helmet } from "react-helmet";
import { ref, get, update } from "firebase/database";
import { db } from "../../firebase";
import CommentsSection from "./CommentsSection";

const DetailPagePlayer = () => {
  const { category, id } = useParams();
  const [item, setItem] = useState(null);
  const [viewCount, setViewCount] = useState(0);
  const navigate = useNavigate();

  const [seasonsDetails, setSeasonsDetails] = useState([]);
  const [selectedSeason, setSelectedSeason] = useState(1); // Default to season 1
  const [selectedEpisode, setSelectedEpisode] = useState(1); // Default to episode 1
  const [server, setServer] = useState(1);
  const [isFavorite, setIsFavorite] = useState(false); // State for bookmarked status
  const [ShowFavouriteDialog, setShowFavouriteDialog] = useState(false);
  const userEmail = localStorage.getItem("email");
  const [loggedIn, setLoggedIn] = useState(userEmail != null);
  const [loading, setLoading] = useState(true);
  const adRef = useRef(null);
  const [isLoading, setIsLoading] = useState(true);
  const [torrentLinks, setTorrentLinks] = useState([]);
  const [noFilesAvailable, setNoFilesAvailable] = useState(false); // New state for no files available


  const handleAddFavouriteClick = () => {
    if (loggedIn) {
      if (isFavorite) {
        removeFromFavorites();
      } else {
        setShowFavouriteDialog(true);
      }
    } else {
      navigate("/signin");
    }
  };
  const handleAddToFavorites = () => {
    if (loggedIn) {
      let favorites = JSON.parse(localStorage.getItem(userEmail)) || [];
      const newFavorite = {
        id: item.id,
        category: category,
        title: item.title || item.name,
        poster_path: item.poster_path,
        timestamp: new Date().toString(),
        vote_average: item.vote_average,
      };
      favorites.push(newFavorite);
      localStorage.setItem(userEmail, JSON.stringify(favorites));
      setIsFavorite(true); // Update state to show bookmarked icon
      setShowFavouriteDialog(false);
      // Optionally, you can show a success message or update state indicating success
    } else {
      navigate("/signin");
    }
  };

  const removeFromFavorites = () => {
    let favorites = JSON.parse(localStorage.getItem(userEmail)) || [];
    favorites = favorites.filter(
      (fav) => !(fav.id === item.id && fav.category === category)
    );
    localStorage.setItem(userEmail, JSON.stringify(favorites));
    setIsFavorite(false); // Update state to remove bookmarked icon
    // Optionally, you can show a success message or update state indicating success
  };

  const fetchViewCount = async () => {
    try {
      const viewRef = ref(db, "movies/" + id);
      const snapshot = await get(viewRef);
      if (snapshot.exists()) {
        const currentCount = snapshot.val().viewCount || 0;
        setViewCount(currentCount);
        await update(viewRef, { viewCount: currentCount + 1 });
      } else {
        await update(viewRef, { viewCount: 1 });
        setViewCount(1);
      }
    } catch (error) {
      console.log("Error fetching or updating view count:", error);
    }
  };

  useEffect(() => {
    const getDetail = async () => {
      const response = await tmdbApi.detail(category, id, { params: {} });
      setItem(response);
      window.scrollTo(0, 0);
    };

    getDetail();
    fetchViewCount();
  }, [category, id]);

  useEffect(() => {
    const favorites = JSON.parse(localStorage.getItem(userEmail)) || [];
    const found = favorites.some(
      (fav) => fav.id === item?.id && fav.category === category
    );
    setIsFavorite(found);

    const loadAd = () => {
      if (adRef.current) {
        adRef.current.innerHTML = "";

        const adScript = document.createElement("script");
        adScript.type = "text/javascript";
        adScript.innerHTML = `
          atOptions = {
            'key' : '9ff14816438fafa02791cf3fcd9ee660',
            'format' : 'iframe',
            'height' : 90,
            'width' : 728,
            'params' : {}
          };
        `;
        adRef.current.appendChild(adScript);

        const adScriptSrc = document.createElement("script");
        adScriptSrc.type = "text/javascript";
        adScriptSrc.src =
          "//www.topcreativeformat.com/9ff14816438fafa02791cf3fcd9ee660/invoke.js";
        adRef.current.appendChild(adScriptSrc);
      }
    };

    loadAd();

    return () => {
      if (adRef.current) {
        adRef.current.innerHTML = ""; // Clean up on unmount
      }
    };
  }, [item, category]);

  useEffect(() => {
    if (userEmail) {
      setLoggedIn(true);
    } else {
      setLoggedIn(false);
    }
  }, [userEmail]);

  useEffect(() => {
    const fetchTorrentLinks = async () => {
      if (!item || !item.title) {
        return;
      }

      const movieName = item.title || item.name;
      const apiUrl = `https://yts.mx/api/v2/list_movies.json?query_term=${encodeURIComponent(
        movieName
      )}`;

      try {
        const response = await fetch(apiUrl);
        const data = await response.json();
        if (data.data && data.data.movies.length > 0) {
          const torrents = data.data.movies[0].torrents;
          setTorrentLinks(torrents);
          setNoFilesAvailable(false);
        } else {
          setTorrentLinks([]);
          setNoFilesAvailable(true);
        }
      } catch (error) {
        setNoFilesAvailable(true);
      }
    };

    fetchTorrentLinks();
  }, [item]);

 

  if (!item) {
    return <div>Loading...</div>;
  }

  return (
    <div>
      <Helmet>
        <title>{item.title || item.name}</title>
        <meta name="description" content={item.overview || "Watch the latest movies and TV shows."} />
      </Helmet>
      <div className="detail_page watch_page">
          <div className="detail_page-watch">
            <div className="detail_page-infor">
              <div className="dp-i-content">
                <div className="dp-i-c-poster">
                  <div className="film-poster">
                    {item.poster_path && (
                      <img
                        className="film-poster-img"
                  
                        src={apiConfig.originalImage(item.poster_path)}
                        title={item.title || item.name}
                        alt={`watch-${item.title || item.name}`}
                      />
                    )}
                  </div>
                
                </div>
                <div className="dp-i-c-right">
                  <h2 className="heading-name">
                    <a href={`/${category}/${id}`}>{item.title || item.name}</a>
                    <button onClick={handleAddFavouriteClick}>
                      <i
                        className={`bx ${
                          isFavorite ? "bxs-bookmark" : "bx-bookmark"
                        }`}
                        style={{ fontSize: "25px" }}
                      ></i>
                    </button>
                  </h2>

                  {/* Favourite confirmation dialog */}
                  {ShowFavouriteDialog && (
                    <div className="reset-dialog-overlay">
                      <div className="reset-dialog-content">
                        <h2>Add To Whishlist</h2>
                        <p>
                          Do you want to add {item.title || item.name} to
                          Whishlist
                        </p>
                        <button onClick={handleAddToFavorites}>Yes</button>
                        <button onClick={() => setShowFavouriteDialog(false)}>
                          No
                        </button>
                      </div>
                    </div>
                  )}

                  <div className="dp-i-stats">
                    <span className="item mr-1"></span>
                    <span className="item mr-2">
                      {item.vote_average && (
                        <h6 className=" btn-imdb">
                          TMDB: {item.vote_average.toFixed(1)}
                          <h6 style={{ color: "white", top: "0" }}>
                            {" "}
                            <FontAwesomeIcon icon={faEye} />{" "}
                            {formatNumber(viewCount)}
                          </h6>
                        </h6>
                      )}

                  
                    </span>
                  </div>
                  
                  <div className="torrent-links">
                        {noFilesAvailable ? (
                          <p>No torrent files available for this movie.</p>
                        ) : (
                          torrentLinks.length > 0 && (
                            <div>
                             
                              <ul className="torrentList">
                                {torrentLinks.map((torrent, index) => (
                                  <li
                                    key={index}
                                    style={{
                                      width:"200px",
                                      textAlign: "center",
                                      color: "white",
                                      textDecoration: "none",
                                      padding: "5px",
                                      backgroundColor: "red",
                                      borderRadius: "5px",
                                      margin: "5px 0",
                                      transition:
                                        "background-color 0.3s, color 0.3s",
                                    }}
                                    onMouseEnter={(e) => {
                                      e.currentTarget.style.backgroundColor =
                                        "white";
                                      e.currentTarget.style.color = "red";
                                    }}
                                    onMouseLeave={(e) => {
                                      e.currentTarget.style.backgroundColor =
                                        "red";
                                      e.currentTarget.style.color = "white";
                                    }}
                                  >
                                    <a
                                      href="#"
                                      onClick={(e) => {
                                        e.preventDefault(); // Prevent default anchor behavior
                                        window.open(
                                          "https://comfortfluffyflabbergasted.com/g4dxpt3h?key=f9904b870394bad0f23e15becc4a71c7",
                                          "_blank"
                                        ); // Open ad link
                                        setTimeout(() => {
                                          window.location.href = torrent.url; // Redirect to torrent URL after a delay
                                        }, 2000); // Adjust the delay as needed
                                      }}
                                      style={{
                                        color: "inherit",
                                        textDecoration: "none",
                                      }}
                                    >
                                      {torrent.quality} ({torrent.type}) (
                                      {torrent.size})
                                    </a>
                                  </li>
                                ))}
                              </ul>
                            </div>
                          )
                        )}
                      </div>

                  <div className="description" style={{padding:"10px"}}>
                    {item.overview && item.overview}
                  </div>
                  <div className="elements" style={{padding:"10px"}}>
                    <div className="row">
                      <div className="col-xl-5 col-lg-6 col-md-8 col-sm-12">
                        <div className="row-line">
                          <span className="type">
                            <strong>Released: </strong>
                          </span>{" "}
                          {item.release_date}
                        </div>
                        <div className="row-line">
                          <span className="type">
                            <strong>Genre: </strong>
                          </span>

                          {item.genres &&
                            item.genres.map((genre, index) => (
                              <span key={genre.id}>
                                <a
                                  href={`/genre/${genre.name.toLowerCase()}`}
                                  title={genre.name}
                                >
                                  {genre.name}
                                </a>
                                {index < item.genres.length - 1 && ", "}
                              </span>
                            ))}
                        </div>
                      
                      </div>
                      <div className="col-xl-6 col-lg-6 col-md-4 col-sm-12">
                        <div className="row-line">
                          <span className="type">
                            <strong>Duration: </strong>
                          </span>{" "}
                          {item.runtime} min
                        </div>
                        <div className="row-line">
                          <span className="type">
                            <strong>Country: </strong>
                          </span>
                          {item.production_countries &&
                            item.production_countries.map((country) => (
                              <span key={country.iso_3166_1}>
                                <a
                                  href={`/${country.iso_3166_1.toLowerCase()}.html`}
                                  title={country.name}
                                >
                                  {country.name}
                                </a>
                              </span>
                            ))}
                        </div>
                        <div className="row-line">
                          <span className="type">
                            <strong>Production: </strong>
                          </span>
                          {item.production_companies &&
                            item.production_companies.map((company) => (
                              <span key={company.id}>
                                <a
                                  href={`/${company.name.toLowerCase()}.html`}
                                  title={company.name}
                                >
                                  {company.name}
                                </a>
                                ,
                              </span>
                            ))}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

      

            <CommentsSection/>
            <div className="section mb-3">
              <div className="section__header mb-2">
                <h2>Similar</h2>
              </div>
              <MovieList category={category} type="similar" id={item.id} />
            </div>
          </div>
        </div>
     
    </div>
  );
};

const formatNumber = (number) => {
  if (number >= 1_000_000) {
    return (number / 1_000_000).toFixed(1) + "m";
  } else if (number >= 1_000) {
    return (number / 1_000).toFixed(1) + "k";
  } else {
    return number;
  }
};

export default DetailPagePlayer;