import React, { useState, useEffect } from "react";
import SwiperCore, { Autoplay } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import Button, { OutlineButton, PlayButton } from "../button/Button";
import Modal, { ModalContent } from "../modal/Modal";
import tmdbApi, { category, movieType } from "../../api/tmdbApi";
import apiConfig from "../../api/apiConfig";
import "./hero-slide.scss";
import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";
import logo from "../../assets/logo2.png";

const HeroSlide = () => {
  SwiperCore.use([Autoplay]);

  const [movieItems, setMovieItems] = useState([]);

  useEffect(() => {
    const getMovies = async () => {
      const params = { page: 1 };
      try {
        const response = await tmdbApi.getMoviesList(movieType.popular, {
          params,
        });
        const moviesWithDetails = await Promise.all(
          response.results.slice(0, 5).map(async (movie) => {
            const details = await tmdbApi.detail(category.movie, movie.id, {
              params: {},
            });
            return {
              ...movie,
              runtime: details.runtime,
              genres: details.genres || [], // Handle missing genres gracefully
            };
          })
        );
        setMovieItems(moviesWithDetails);
      } catch (error) {
        console.log("Error fetching movies:", error);
      }
    };
    getMovies();
  }, []);

  const formatRuntime = (runtime) => {
    if (!runtime) return "N/A";
    const hours = Math.floor(runtime / 60);
    const minutes = runtime % 60;
    return `${hours}h ${minutes}m`;
  };

  return (
    <div className="hero-slide">
      <Swiper
        modules={[Autoplay]}
        grabCursor={true}
        spaceBetween={0}
        slidesPerView={1}
        autoplay={{ delay: 8000 }}
      >
        {movieItems.map((item, i) => (
          <SwiperSlide key={i}>
            {({ isActive }) => (
              <HeroSlideItem
                item={item}
                isActive={isActive}
                formatRuntime={formatRuntime}
              />
            )}
          </SwiperSlide>
        ))}
      </Swiper>
      {movieItems.map((item, i) => (
        <TrailerModal key={i} item={item} />
      ))}
    </div>
  );
};

const HeroSlideItem = ({ item, isActive, formatRuntime }) => {
  const navigate = useNavigate();

  const background = apiConfig.originalImage(
    item.backdrop_path || item.poster_path
  );

  const keywords = `free movies online, watch ${item.title} online free`;

  const setModalActive = async () => {
    const modal = document.querySelector(`#modal_${item.id}`);
    const videos = await tmdbApi.getVideos(category.movie, item.id);
    if (videos.results.length > 0) {
      const videoSrc = "https://www.youtube.com/embed/" + videos.results[0].key;
      modal
        .querySelector(".modal__content > iframe")
        .setAttribute("src", videoSrc);
    } else {
      modal.querySelector(".modal__content").innerHTML = "No trailer";
    }
    modal.classList.toggle("active");
  };

  return (
    <div
      className={`hero-slide__item ${isActive ? "active" : ""}`}
      style={{ backgroundImage: `url(${background})` }}
    >
      <Helmet>
        <title>Film TV - HD Movies & Tv series</title>
        <meta name="title" content="Film TV - HD Movies & Tv series" />
        <meta name="keywords" content={keywords} />
      </Helmet>

      <div className="hero-slide__item__content container">
        <div className="hero-slide__item__content__info">
          <h1 className="title">{item.title}</h1>
          <div className="overview">{item.overview}</div>
          <div className="other-details">
          <div className="year">
            {item.release_date.substring(0, 4)} | {item.original_language}
          </div>
          <div className="vote-section">
            <div className="vote">
              {item.vote_average.toFixed(1)} <i className="bx bxs-star"></i>
            </div>
            <div className="runtime"> | {formatRuntime(item.runtime)}</div>
          </div>

          {/* Safely render genres */}
          {item.genres && item.genres.length > 0 && (
            <div className="genres">
              {item.genres.slice(0, 3).map((genre, i) => (
                <span key={i} className="genre">
                  {genre.name}
                  {i < item.genres.length - 1 && " • "}{" "}
                </span>
              ))}
            </div>
          )}
          </div>
      

          <div className="btns btn-container">
            <PlayButton onClick={() => navigate(`/movie/${item.id}`)}>
              <div className="icon-container">
                <i className="bx bx-play"></i> <h5>PLAY</h5>
              </div>
            </PlayButton>
            <OutlineButton onClick={setModalActive}>
              WATCH TRAILER
            </OutlineButton>
          </div>
        </div>
        <div className="hero-slide__item__content__poster">
          <img src={apiConfig.w500Image(item.poster_path)} alt={item.title} />
        </div>
      </div>
    </div>
  );
};

const TrailerModal = ({ item }) => {
  const iframeRef = React.useRef(null);

  const onClose = () => iframeRef.current.setAttribute("src", "");

  return (
    <Modal active={false} id={`modal_${item.id}`}>
      <ModalContent onClose={onClose}>
        <iframe
          ref={iframeRef}
          width="100%"
          height="500px"
          title="trailer"
        ></iframe>
      </ModalContent>
    </Modal>
  );
};

export default HeroSlide;
